import RepositoryFactory from "@/repositories/RepositoryFactory";

const ArticleRepository = RepositoryFactory.get("article");

export const articleModule = {
  state: () => ({
    articles: [],
  }),
  getters: {},
  mutations: {
    setArticles(state, articles) {
      state.articles = articles;
    },
    updateArticle(state, payload) {
      state.articles = state.articles.map((article) => {
        if (article.id === payload.id) {
          return Object.assign({}, article, payload);
        }
        return article;
      });
    },
  },
  actions: {
    fetchArticles(state) {
      return new Promise((resolve, reject) => {
          ArticleRepository.list()
            .then((response) => {
              state.commit("setArticles", response.data);
              resolve();
            })
            .catch((e) => {
              reject(e);
            });
        })
    },
  },
  namespaced: true,
};

<template>
  <div class="footer-block">
    <div class="main-wrapper">
      <div class="item">
        <div>
          <img :src="logo_svg" alt="" class="logo">
        </div>
        <div>
          <a href="#"><img :src="tg" alt="" class="icon"></a>
          <a href="#"><img :src="vk" alt="" class="icon"></a>
        </div>
      </div>
      <div class="item menu">
        <div class="menu-top">
          <a href="#" class="menu-text">О нас</a>
          <a href="#" class="menu-text">Наша деятельность</a>
          <a href="#" class="menu-text">Статьи</a>
          <a href="#" class="menu-text">Контакты</a>
          <a href="#" class="menu-text">Партнеры</a>
          <a href="#" class="menu-text last">Политика конфиденциальности</a>
        </div>
      </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "FooterBar",
  data: function () {
    return {
      logo_svg: require("@/assets/img/logo.svg"),
      vk: require("@/assets/img/vk.svg"),
      tg: require("@/assets/img/tg.svg")
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";


.footer-block {
  padding-bottom: 100px;
  padding-top: 100px;
  background-color: $olive-light;
  .main-wrapper {
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
  }
  @media screen and (max-width: $width-l) {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  .logo {
    margin-bottom: 42px;
    @media screen and (max-width: $width-m) {
      max-width: 160px;
    }
  }
  .icon {
    margin-right: 27px;
  }
  .item {
    width: 50%;
  }
  .menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  }
  .menu-text {
    margin-bottom: 20px;
    &.last {
      margin-top: 40px;
      text-decoration: underline;
      text-underline-offset: 5px;
      text-decoration-thickness: 1px;
      @media screen and (max-width: $width-xl) {
        margin-top: 10px;
      }
    }
  }
  .menu-top {
    display: flex;
    flex-direction: column;
  }
}
</style>

<template>
  <div class="header-block" :class="{is_sticky: this.isSticky}">
    <div class="wrapper">
      <img class="logo" :src="logo" alt="" @click="$router.push('/')">
      <div class="mobile-menu">
      <img :src="logo" alt="" @click="$router.push('/')">
      <label for="check">
        <input type="checkbox" id="check" v-model="is_menu_open"/>
        <span></span>
        <span></span>
        <span></span>
      </label>
      </div>
      <div class="menu-wrapper" :class="{ is_mobile_view: !this.is_menu_open }">
        <div class="menu" >
          <router-link
              v-for="route in $router.options.routes.filter(i => i.path !== '/')"
              :key="route.path"
              :to="route.path"
              :class="
              { disabled: route.disabled }"
              class="main menu-text"
          >
            {{ route.name }}
          </router-link>
          <a @click="scrollDown" href="javascript:" class="main menu-text">Контакты</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: ["is_open"],
  data() {
    return {
      isSticky: false,
      scrollPosition:0,
      is_menu_open: this.is_open,
      logo: require("@/assets/img/logo_main.svg"),
    }
  },
    created () {
      window.addEventListener('scroll', this.handleScroll);
    },
    unmounted () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods:{
      handleScroll(){
          this.scrollPosition = window.scrollY;
          this.isSticky = this.scrollPosition >= 200;
      },
      scrollDown() {
        this.is_menu_open = false;
        window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
      }
    },
    watch: {
      '$route' () {
        this.is_menu_open = false
      }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.logo {
  margin-left: 40px;
  width: 202px;
  cursor: pointer;
}
.mobile-menu {
  display: none;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 10px 20px;

  img {
    width: 120px;
    cursor: pointer;
  }
  @media screen and (max-width: $phone-width) {
    display: flex;
  }
}

.header-block {
  width: 100%;
  position: relative;
  background-color: $white;
  border-bottom: 1px solid $olive;
}

.wrapper {
  width: 100%;
  height: 121px;
  margin: auto;
  max-width: 1680px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
}

.menu-wrapper {
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &.is_mobile_view {
    @media screen and (max-width: $phone-width) {
       max-height: 0;
    }
  }
}

.main {
  margin-right: 40px;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}


.is_sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  width: 100%;
  background-color: $white;
  -webkit-animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
  animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  @media screen and (max-width: $phone-width) {
    padding: 0;
  }
}

label{
 display:flex;
  flex-direction:column;
  width:50px;
  cursor:pointer;
}

label span{
  background: $dark-green;
  border-radius:10px;
  height: 3px;
  margin: 4px 0;
  transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);

}


span:nth-of-type(1){
  width:50%;

}

span:nth-of-type(2){
  width:100%;
}


span:nth-of-type(3){
  width:75%;

}


input[type="checkbox"]{
  display:none;
}


input[type="checkbox"]:checked ~ span:nth-of-type(1){
  transform-origin:bottom;
  transform:rotatez(45deg) translate(3px,0px)
}


input[type="checkbox"]:checked ~ span:nth-of-type(2){

  transform-origin:top;
  transform:rotatez(-45deg)
}


input[type="checkbox"]:checked ~ span:nth-of-type(3){

  transform-origin:bottom;
  width:50%;
  transform: translate(19px,-3px) rotatez(45deg);

}


</style>
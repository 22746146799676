export function set_img(src) {
    const img = new Image();
    img.src = src;
    return img;
}

export const pageModule = {
  state: () => ({
    pages: {
        'home': {
            id: 1,
            img: set_img(require("@/assets/old/dog_1.jpg")),
        },
        'about': {
            id: 2,
            img: set_img(require('@/assets/old/dog_1.jpg')),
        },
        'helpers': {
            id: 3,
            img: set_img(require('@/assets/old/back_3.jpeg')),
        },
        'goals': {
            id: 4,
            img: set_img(require('@/assets/old/back_7.jpeg')),
        },
        'partners': {
            id: 5,
            img: set_img(require('@/assets/old/back_6.jpeg')),
        }
    }
  }),
    namespaced: true,
};
import axios from "axios";
import {BACKEND_HOST} from "@/constants";


const baseURL = `${BACKEND_HOST}/api/v1`;

const Client = axios.create({
  baseURL,
  headers: {
    "Accept-Language": "ru-RU",
  },
});

export default Client;

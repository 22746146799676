import Client from "@/repositories/Client";

const resource = "/articles";

export default {
  list() {
    return Client.get(`${resource}/`);
  },
  get(id) {
    return Client.get(`${resource}/${id}/`);
  },
};

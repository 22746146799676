<template>
  <div class="m_100">
    <bread-crumbs>Наша команда</bread-crumbs>
  </div>
  <div class="main-wrapper">
    <div>
      <div class="w_50_wrapper m_100">
        <h1>НАША КОМАНДА</h1>
      </div>
      <div class="p-about m_50 head_text">
        Мы - инициативная группа с самой грандиозной целью: сделать мир лучше!
      </div>
      <div class="w_50_wrapper m_50 help_text">
        <div class="text w_50">В нашем проекте «Дело в собаке» по всей стране более 60 волонтеров: наша команда состоит из владельцев собак, профессиональных кинологов, переводчиков, просто энтузиастов своего дела.</div>
      </div>
      <div class="w_50_wrapper m_100 help_text">
        <div class="text w_50">Мы знаем, как собака может помогать в смягчении и устранении различных симптомов. На данный момент, мы столкнулись с большим количеством людей, которые нуждаются в собаках психиатрической помощи, собаках для людей с эпилепсией, диабетом, аллергией, сердечно-сосудистыми заболеваниями.</div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState } from "vuex";
import BreadCrumbs from "@/components/BreadCrumbs.vue";

export default {
  name: "TeamPage",
  components: { BreadCrumbs },
  computed: {
    ...mapState({
      members: (state) => state.member.members,
      pages: (state) => state.page.pages,
    }),
  },
  mounted() {
    document.title = 'Дело в собаке | Наша команда';
  },
  data() {
    return {};
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

h1 {
  color: $black;
}

.help_text {
  align-items: center;

  .text {
    max-width: 750px;
  }
}

.head_text {
  max-width: 700px;
}
</style>
<script>
export default {
  name: "QuestionAnswerComponent",
  props: [
    "questions"
  ],
  data: function () {
    return {
      show: null,
      arrow_down: require("@/assets/img/arrow_down.svg"),
      arrow_up: require("@/assets/img/arrow_up.png"),
    };
  },
}
</script>

<template>
  <div class="question-block m_100">
    <h2>ВОПРОСЫ И ОТВЕТЫ</h2>
    <div class="answers">
      <div v-for="item in questions" v-bind:key="item.id" @click="show = item.id">
        <div class="question" style="position: relative;">
          <div class="p">{{ item.question }}</div>
          <img v-if="show == item.id" style="position: absolute; right: 60px;" :src="arrow_up" alt="">
          <img v-if="show !== item.id" :src="arrow_down" alt="">
        </div>

        <div class="answer" v-if="show == item.id">
          <div class="p" v-for="(text, index) in item.answer" v-bind:key="index">
            <div style="height: 10px;"></div>
            {{ text }}
            <div style="height: 10px;"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/css/variables.scss";


.question-block {
  margin-top: 302px;

  @media screen and (max-width: $width-xl) {
    margin-top: 231px;
  }

  @media screen and (max-width: $width-l) {
    margin-top: 214px;
  }

  .answers {
    margin-top: 50px;

    @media screen and (max-width: $width-l) {
      margin-top: 40px;
    }

    .question {
      height: 80px;
      background-color: $white;
      border-radius: 20px;
      padding: 15px 60px 15px 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      @media screen and (max-width: $width-l) {
        margin-bottom: 10px;
      }
    }

    .answer {
      height: auto;
      background: rgb(248, 248, 248);
      border-radius: 20px;
      padding: 15px 60px 15px 60px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      @media screen and (max-width: $width-l) {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
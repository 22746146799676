import { createStore } from "vuex";
import { memberModule } from "@/store/member";
import { articleModule } from "@/store/article";
import {shortcutModule} from "@/store/shortcut";
import {pageModule} from "@/store/pages";

const store = createStore({
  modules: {
    member: memberModule,
    article: articleModule,
    shortcut: shortcutModule,
    page: pageModule,
  },
});

export default store;

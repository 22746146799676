import '@/assets/css/rest.css';
import '@/assets/css/main.scss';
import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router/router";
import store from "@/store";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faTelegram, faVk, faInstagram} from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faTelegram, faVk, faInstagram)


const app = createApp(App);

router.app = app;

app.use(router);
app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount("#app");

<template>
  <div class="m_100">
    <bread-crumbs>Наши партнеры</bread-crumbs>
  </div>
  <div class="main-wrapper">
    <div>
      <div class="w_50_wrapper m_100">
        <h1>НАШИ ПАРТНЕРЫ</h1>
      </div>
      <div class="p-about m_50 head_text">
        Мы благодарны партнёрам за поддержку «Дело в собаке»!
      </div>
      <div class="w_50_wrapper m_100 help_text">
        <div class="text w_50">Благодаря партнерству больше людей смогут получить помощь, и чем шире будет распространено понимание роли и пользы собак-помощников, тем больше поддержки и понимания они получат от общества. Это поможет создать благоприятную среду и поддержку для проекта, а также увеличению инклюзии и развитию социальной среды.</div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState } from "vuex";
import BreadCrumbs from "@/components/BreadCrumbs.vue";

export default {
  name: "PartnersPage",
  components: { BreadCrumbs },
  computed: {
    ...mapState({
      members: (state) => state.member.members,
      pages: (state) => state.page.pages,
    }),
  },
  mounted() {
    document.title = 'Дело в собаке | Партнеры';
  },
  data() {
    return {};
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

h1 {
  color: $black;
}

.help_text {
  align-items: center;

  .text {
    font-size: 20px;
    max-width: 750px;
  }
}

.head_text {
  max-width: 700px;
}
</style>
import MemberRepository from "@/repositories/MemberRepository";
import ArticleRepository from "@/repositories/ArticleRepository";
import ShortcutRepository from "@/repositories/ShortcutRepository";
import FeedbackRepository from "@/repositories/FeedbackRepository";

const repositories = {
  member: MemberRepository,
  article: ArticleRepository,
  shortcut: ShortcutRepository,
  feedback: FeedbackRepository,
};
export default {
  get: (name) => repositories[name],
};

<script>
import ButtonSimple from "@/components/ButtonSimple.vue";

export default {
  name: "InfoPage",
  components: {ButtonSimple},
  data: function () {
    return {
      ellipse_1: require("@/assets/img/client_el_1.svg"),
      ellipse_2: require("@/assets/img/client_el_2.svg"),
      ellipse_3: require("@/assets/img/client_el_3.svg"),
      dot: require("@/assets/img/dot.svg"),
      ok: require("@/assets/img/ok.svg"),
      girl_and_dog: require("@/assets/img/client_girl_and_dog.svg"),
      step_1: require("@/assets/img/client_step_1.svg"),
      step_2: require("@/assets/img/client_step_2.svg"),
      step_3: require("@/assets/img/client_step_3.svg"),
      step_4: require("@/assets/img/client_step_4.svg"),
      step_5: require("@/assets/img/client_step_5.svg"),
      step_6: require("@/assets/img/client_step_6.svg"),
      step_arrow: require("@/assets/img/client_step_arrow.svg"),
      who_items: [
        {
          'id':1,
          'text': 'Расстройства личности'
        },
       {
          'id':2,
          'text': 'Постравматическое расстройство'
        },
        {
          'id':3,
          'text': 'Аффективные расстройства (БАР и др.)'
        },
        {
          'id':4,
          'text': 'Обсессивно-компульствное расстройство (ОКР)'
        },
        {
          'id':5,
          'text': 'Депрессия'
        },
        {
          'id':6,
          'text': 'Диабет'
        },
        {
          'id':7,
          'text': 'Шизофрения'
        },
        {
          'id':8,
          'text': 'Эпилепсия'
        },
        {
          'id':9,
          'text': 'Тревожные расстройства'
        },
        {
          'id':10,
          'text': 'Сердечно-сосудистые заболевания (СПОТ, хроническая аритмия, хроническая тахикардия)'
        },
        {
          'id':11,
          'text': 'Диссоциативные расстройства'
        },
        {
          'id':12,
          'text': 'ПТСР'
        },
        {
          'id':13,
          'text': 'РАС и СДВГ'
        },
        {
          'id':14,
          'text': 'Астма и ХОБЛ'
        },
        {
          'id':15,
          'text': 'Тяжелые формы пищевой аллергии'
        },
      ],
      what_items: [
          {
            'id':1,
            'text': 'Помогаем выбрать породу собаки и заводчика',
            'img': require("@/assets/img/client_what_1.svg")
          },
         {
            'id':2,
            'text': 'Проводим тестирование щенка или взрослой собаки',
           'img': require("@/assets/img/client_what_2.svg")
          },
          {
            'id':3,
            'text': 'Поможем найти лечащего врача и/или психотерапевта',
             'img': require("@/assets/img/client_what_3.svg")
          },
          {
            'id':4,
            'text': 'Обучим вашу собаку по профилю «собака-помощник»',
             'img': require("@/assets/img/client_what_4.svg")
          },
      ],
      learn_items: [
        {
          'id':1,
          'text': 'Отслеживать и реагировать на симптомы заболевания'
        },
       {
          'id':2,
          'text': 'Предупреждать о приступах и/или возможных триггерах'
        },
        {
          'id':3,
          'text': 'Приносить телефон и лекарства'
        },
        {
          'id':4,
          'text': 'Обращаться за помощью к окружающим'
        },
        {
          'id':5,
          'text': 'Сопровождать человека в общественных местах'
        },
        {
          'id':6,
          'text': 'Оказывать физическую и моральную поддержку во время приступа'
        },
      ],
      how_help_items: [
  {
    'id': 1,
    'title': 'ИНТЕРВЬЮ',
    'p': [
      {
        'id': 1,
        'text': 'Его проводят руководитель проекта «Дело в собаке» Нюта Гинсбург и наш главный кинолог Елена Молодцова. Руководитель направления «Подготовка собак для людей с диабетом» Катя Горячёва принимает участие в интервью с людьми с соответствующими диагнозами.'
      },
      {
        'id': 2,
        'text': 'Никто другой в целях конфиденциальности и по этическим соображениям правом проводить интервью с потенциальным благополучателем не обладает.',
      },
      {
        'id': 3,
        'text': 'От благополучателя на данном этапе требуется быть откровенным. Только так мы можем получить важную для дальнейшей работы информацию, сделав наше сотрудничество максимально эффективным.',
      },
      {
        'id': 4,
        'text': 'Со своей стороны мы гарантируем бережное отношение к потенциальному благополучателю и сохранение врачебной тайны. Мы не требуем справок от врача с подтверждением диагноза.',
      },
      {
        'id': 4,
        'text': 'Но обязателен разговор с психотерапевтом проекта “Дело в собаке”.',
      }
    ],
    'icon': require("@/assets/img/client_help_1.svg"),
  },
  {
    'id': 2,
    'title': 'Анкетирование',
    'p': [
      {
        'id': 1,
        'text': 'По итогам видеоинтервью специалисты проекта «Дело в собаке» разрабатывают индивидуальный протокол обучения. После этого мы отправляем благополучателю анкету, которую нужно заполнить в установленные сроки (указаны в анкете).'
      },
      {
        'id': 2,
        'text': 'Регламент интервью не предполагает некоторых вопросов, ответы на которые, тем не менее, нам тоже нужны для построения обучающего процесса. Если у благополучателя возникают вопросы в процессе заполнения анкеты, мы всегда помогаем прояснить сложные моменты.',
      }
    ],
    'icon': require("@/assets/img/client_help_2.svg"),
  },
  {
    'id': 3,
    'title': 'Тестирование собаки',
    'p': [
      {
        'id': 1,
        'text': 'Если в городе, где живёт благополучатель, есть наш кинолог, то тестирование собаки проводит он. В ином случае мы договариваемся о проведении видеотестирования. '
      },
      {
        'id': 2,
        'text': 'Этим этапом никак нельзя пренебречь, потому что он помогает увидеть нюансы реакций и понять область возможного применения собаки, а также оценить обьём работы*. У тестирования есть регламент, разработанный нашими специалистами, и методическая инструкция.',
      }
    ],
    'icon': require("@/assets/img/client_help_3.svg"),
  }
      ]
    }
  }
}
</script>

<template>
  <div class="all-wrapper">
    <div class="main-wrapper">
      <div class="main-text">
        <h1>
          ИНФОРМАЦИЯ ДЛЯ БЛАГОПОЛУЧАТЕЛЕЙ
        </h1>
      </div>
      <div class="main-img-block w_50_wrapper">
        <div class="girl_and_dog w_50">
          <img :src="girl_and_dog" alt="" class="img">
        </div>
        <div class="w_50 p">
          <div class="item">
            <div class="line-wrapper"><div class="line line-1"></div></div><div>Кому мы помогаем</div>
          </div>
          <div class="item">
            <div class="line-wrapper"><div class="line line-2"></div></div><div>Что мы делаем</div>
          </div>
          <div class="item">
            <div class="line-wrapper"><div class="line line-1"></div></div><div>Как получить</div>
          </div>
        </div>
      </div>
    </div>
      <div class="relative">
        <div class="ellipses-wrapper el-1" v-bind:style="{ backgroundImage: 'url(' + ellipse_1 + ')' }"></div>
      </div>
    <div class="main-wrapper">
      <div class="who-text">
        <h2 class="m_50">КОМУ МЫ ПОМОГАЕМ</h2>
        <div class="p who-p">Мы оказываем помощь гражданами РФ в возрасте от 18 лет со следующими хроническими заболеваниями:</div>
        <div class="info w_50_wrapper m_100 p">
          <div
              v-for="item in who_items"
              v-bind:key="item.id"
              class="item w_50"
          >
            <img :src="dot" alt="" class="img">
            <div class="item_text">
              {{item.text}}
            </div>
          </div>

        </div>
      </div>
      <div class="what-text m_100">
        <h2 class="m_50">ЧТО МЫ ДЕЛАЕМ</h2>
        <div class="what-items p w_50_wrapper wrap">
          <div
              v-for="item in what_items"
              v-bind:key="item.id"
              class="item w_50"
          >
            <img :src="item.img" alt="" class="img">
            <div class="p"> {{item.text}}</div>
          </div>
        </div>
      </div>
      <div class="learn-text">
        <h2 class="m_50">ЧЕМУ МЫ УЧИМ СОБАК?</h2>
        <div class="what-items p w_50_wrapper wrap">
            <div
              v-for="item in learn_items"
              v-bind:key="item.id"
              class="item w_50"
            >
              <img :src="ok" alt="" class="img">
              <div class="item_text">
                {{item.text}}
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="relative">
      <div class="ellipses-wrapper el-2" v-bind:style="{ backgroundImage: 'url(' + ellipse_2 + ')' }"></div>
    </div>
    <div class="main-wrapper">
      <div class="how-help-text">
        <h2 class="m_50">КАК ПОЛУЧИТЬ ПОМОЩЬ</h2>
        <div
            class="olive-block"
            v-for="item in how_help_items"
            v-bind:key="item.id"
        >
          <div>
            <div class="title">
              <img :src="item.icon" alt="" class="img">
              <h3>{{item.title}}</h3>
            </div>
            <div>
              <div
                  class="text"
                  v-for="t in item.p"
                  v-bind:key="t.id"
              >
                {{t.text}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="step-text">
        <h2 class="m_100">ЭТАПЫ УЧАСТИЯ В ПРОЕКТЕ</h2>
        <div class="items p w_20_wrapper">
          <div class="item w_20">
            <img :src="step_1" alt="" class="img">
            <div>Заявка на сайте</div>
          </div>
          <div class="arrow w_20">
            <img :src="step_arrow" alt="" class="img">
          </div>
          <div class="item w_20">
            <img :src="step_2" alt="" class="img">
            <div>Видео-интервью</div>
          </div>
          <div class="arrow w_20">
            <img :src="step_arrow" alt="" class="img">
          </div>
          <div class="item w_20">
            <img :src="step_3" alt="" class="img">
            <div>Заполнение анкеты</div>
          </div>
          <div class="item w_20">
            <img :src="step_4" alt="" class="img">
            <div>Тестирование собаки</div>
          </div>
          <div class="arrow w_20">
            <img :src="step_arrow" alt="" class="img">
          </div>
          <div class="item w_20">
            <img :src="step_5" alt="" class="img">
            <div>Первичная консультация с кинологом</div>
          </div>
          <div class="arrow w_20">
            <img :src="step_arrow" alt="" class="img">
          </div>
          <div class="item w_20">
            <img :src="step_6" alt="" class="img">
            <div>Начало обучения</div>
          </div>
        </div>
      </div>
    </div>
    <div class="relative">
      <div class="ellipses-wrapper el-3" v-bind:style="{ backgroundImage: 'url(' + ellipse_3 + ')' }"></div>
    </div>
    <div class="main-wrapper">
      <div class="contacts">
        <div class="w_50_wrapper">
          <div class="w_50 left_item">
            <h2 class="m_50">ПОЛУЧЕНИЕ<br>ПОМОЩИ</h2>
            <div class="text" style="margin-bottom: 30px">
              Проект «Дело в собаке» принимает заявки благополучателей круглый год, но мы просим вас учитывать, что с момента первичного собеседования до начала обучения в данное время проходит около полутора лет.
            </div>
            <div class="text">
              Мы вносим участников в листы ожидания до&nbsp;старта нового потока.
            </div>
          </div>
          <div class="w_50">
            <h3> Заполните заявку</h3>
            <div class="text">
              <input class="textbox" type="text" id="name" name="name" placeholder="Введите имя"/>
              <input class="textbox" type="email" id="email" name="email" placeholder="Ваш e-mail для связи"/>
              <input class="textbox" type="text" id="phone" name="phone" placeholder="Ваш телефон"/>
              <div class="check">
                <input id="confirm" name="confirm" type="checkbox">
                <label for="confirm">Согласен(а) с <a href="">политикой конфиденциальности</a></label>
              </div>
              <button-simple mode="dark">Получить помощь</button-simple>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
@import "@/assets/css/variables.scss";
.all-wrapper {
  padding-top: 100px;
  background-color: $olive-light;
  position: relative;
  z-index: 1;
}

.w_50_wrapper {
  display: flex;
  justify-content: space-between;
  .w_50 {
    width: 50%;
    @media screen and (max-width: $width-l) {
      width: 100%;
    }
  }
}
.m_50 {
  margin-bottom: 50px;
  @media screen and (max-width: $width-l) {
    margin-bottom: 40px;
  }
}

.girl_and_dog {
  img {
    max-width: 414px;
  }
}
.ellipses-wrapper {
  position: absolute;
  display: block;
  width: 100%;
  z-index: -1;
  background-position: top;
  background-size: auto;
  background-repeat: no-repeat;
  @media screen and (max-width: $width-l) {
    background-size: cover;
  }
  &.el-1 {
    top: -100px;
    height: 980px;
    @media screen and (max-width: $width-l) {
      top: 80px;
      height: 800px;
      background-size: cover;
    }
    @media screen and (max-width: $width-m) {
      top: -78px;
      height: 1000px;
    }
  }
  &.el-2 {
    top: 77px;
    height: 1986px;
    @media screen and (max-width: $width-l) {
      top: 80px;
      height: 800px;
      background-size: cover;
    }
    @media screen and (max-width: $width-m) {
      top: -78px;
      height: 1000px;
    }
  }
  &.el-3 {
    top: 77px;
    height: 1000px;
    @media screen and (max-width: $width-l) {
      top: 80px;
      height: 800px;
      background-size: cover;
    }
    @media screen and (max-width: $width-m) {
      top: -78px;
      height: 1000px;
    }
  }
}

.info {
  &.w_50_wrapper {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
    img {
      width: 25px;
      margin-top: 3px;
    }
    .item_text {
      margin-left: 20px;
    }
  }
}

.main-img-block {
  margin-top: 30px;
  margin-bottom: 225px;
  &.w_50_wrapper {
    justify-content: center;
    align-items: center;
  }
  .item {
    display: flex;
    align-items: center;
  }
  .line-wrapper {
    width: 122px;
    display: flex;
    margin-right: 18px;
    justify-content: flex-end;
  }
  .line {
    height: 1px;
    background-color: $dark-green;
  }
  .line-1 {
    width: 122px;
  }
  .line-2 {
    width: 62px;
  }
}

.what-text {
  margin-top: 120px;
  .what-items {
    .item:nth-child(odd) {
      flex:0 1 calc(50% - 30px);
      margin-right: 30px;
    }
    .item {
      padding: 30px;
      width: 50%;
      border-radius: 20px;
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;

      img {
        width: 80px;
      }
      .p {
        margin-left: 20px;
      }
    }
  }
}

.who-text {
  .who-p {
    max-width: 690px;
    margin-bottom: 30px;
  }
}

.learn-text {
  &.w_50_wrapper {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
    img {
      width: 38px;
      margin-top: 3px;
    }
    .item_text {
      margin-left: 20px;
    }
  }
}

.how-help-text {
  margin-top: 194px;
  .olive-block {
    background-color: $olive-light;
    padding: 60px;
    border-radius: 20px;
    margin-bottom: 50px;
    .title {
      display: flex;
      align-items: center;
      h3 {
        text-transform: uppercase;
        margin-left: 24px;
      }
    }
    h2 {
      img {
        width: 48px;
        height: 48px;
      }
    }
    .text {
      margin-top: 30px;
    }
  }
}

.step-text {
  margin-top: 311px;
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    height: 175px;
    img {
      height: 75px;
      margin-bottom: 34px;
    }
  }
}

.contacts {
  margin-top: 248px;
  padding-bottom: 200px;
  a {
    color: $dark-green;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 1px;
  }
  button {
    width: 100%;
    margin-top: 20px;
  }
  h3 {
    margin-bottom: 24px;
  }
  .left_item {
    padding-right: 100px;
  }
  input {
    &.textbox {
      margin-bottom: 10px;
      width: 100%;
      background-color: #eef1ed;
      border-radius: 60px;
      padding: 30px;
    }
  }
  .check {
    display: flex;
    margin-top: 10px;
    align-items: center;
    input {
      border-radius: 5px;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
}

.w_20_wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  .w_20 {
    width: 20%;
    @media screen and (max-width: $width-l) {
      width: 100%;
    }
  }
}

.wrap {
  flex-direction: row;
  flex-wrap: wrap;
}

</style>
<template>
  <div class="body">
    <div class="home-block">
      <div class="header-first">
        <h1>ОБУЧАЕМ СОБАК</h1>
        <ButtonSimple>Стать волонтером</ButtonSimple>
      </div>
      <div class="header-second">
        <div class="relative">
          <h1><img :src="items" alt="" class="items">ПОМОГАТЬ ЛЮДЯМ</h1>
        </div>

      </div>
      <div class="girl_and_dog">
          <img :src="girl_and_dog" alt="" class="img">
          <div class="relative">
            <div class="p">Работаем с собаками-помощниками для&nbsp;людей с&nbsp;ментальными, неврологическими, эндокринными и&nbsp;другими заболеваниями</div>
            <img :src="arrow_top" alt="" class="arrow_top">
            <ButtonSimple mode="dark">Получить помощь</ButtonSimple>
          </div>
      </div>
    </div>
      <div class="relative">
      <div class="ellipses-wrapper" v-bind:style="{ backgroundImage: 'url(' + ellipses + ')' }"></div>
    </div>
    <div class="main-wrapper">
    <div class="mission m_100">
      <div class="wrapper main-block between relative">
        <img :src="arrow_mission" class="arrow_mission">
        <div class="block first-block"><h3>НАША МИССИЯ</h3>
          <div class="text first-block">
            Реабилитация людей с&nbsp;хроническими заболеваниями с помощью специально обученных собак.
          </div>
        </div>
        <div class="block second-block">
          <h2>КТО МЫ</h2>
          <div class="text">
            Мы&nbsp;команда энтузиастов, которые работают с&nbsp;собаками-помощниками, способными облегчить людям жизнь.
          </div>
          <ButtonSimple>Стать волонтером</ButtonSimple>
        </div>
      </div>
    </div>
    <div class="info m_100">
      <div class="item">
        <div><h2>14</h2></div>
        <hr>
        <div class="text">Кинологов</div>
      </div>
      <div class="item">
        <div><h2>26</h2></div>
        <hr>
        <div class="text">Благополучателей</div>
      </div>
      <div class="item">
        <div><h2>64</h2></div>
        <hr>
        <div class="text">Волонтера</div>
      </div>
    </div>
    <div class="help-block m_100">
      <div class="item image-item">
        <img :src="help" alt="" class="help">
      </div>
      <div class="item text-item">
        <h2>КОМУ МЫ МОЖЕМ&nbsp;ПОМОЧЬ</h2>
        <div class="help-item">
          <img :src="help_icon_1" alt="">
          <div class="p">Гражданам РФ, достигшим 18 лет</div>
        </div>
        <div class="help-item">
          <img :src="help_icon_2" alt="">
          <div class="p">У которых есть собака, <span>подходящая для&nbsp;работы</span>, или возможность её&nbsp;приобрести</div>
        </div>
        <div class="help-item">
          <img :src="help_icon_3" alt="">
          <div class="p">Имеющим хронические&nbsp;заболевания, находящимся под&nbsp;контролем врача</div>
        </div>
        <ButtonSimple mode="dark">Получить помощь</ButtonSimple>
      </div>
    </div>

    <QuestionAnswerComponent :questions="this.questions"></QuestionAnswerComponent>

    <div class="arrow-block m_100">
      <div class="arrow_long_right"></div>
      <ButtonSimple mode="dark">Получить помощь</ButtonSimple>
    </div>

    <div class="article-block m_100">
      <h2>ПОЛЕЗНО ЗНАТЬ</h2>
      <div class="wrapper glide">
        <div class="glide__track" data-glide-el="track">
          <div class="glide__slides">
            <div class="item glide__slide">
              <img :src="article_1" alt="">
              <div class="inner">
                <div class="p">Оценка потенциальной собаки-помощника</div>
                <ButtonSimple>Читать статью</ButtonSimple>
              </div>
            </div>

            <div class="item glide__slide">
              <img :src="article_2" alt="">
              <div class="inner">
                <div class="p">Психиатрическая служебная&nbsp;собака</div>
                <ButtonSimple>Читать статью</ButtonSimple>
              </div>
            </div>

            <div class="item glide__slide">
              <img :src="article_3" alt="">
              <div class="inner">
                <div class="p">Собаки, предупреждающие о приступах</div>
                <ButtonSimple>Читать статью</ButtonSimple>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  <div class="hr-block main-wrapper"></div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ButtonSimple from "@/components/ButtonSimple.vue";
import QuestionAnswerComponent from "@/components/QuestionAnswerComponent.vue";
import Glide from '@glidejs/glide'

export default {
  name: "HomePage",
  components: {QuestionAnswerComponent, ButtonSimple},
  mounted(){
    document.title = 'Дело в собаке | Главная';
    new Glide('.glide', {
      type: 'carousel',
      startAt: 0,
      perView: 1.5
    }).mount();
  },
  computed: {
    ...mapState({
      pages: (state) => state.page.pages
    }),
  },
  data: function () {
    return {
      logo_5: require("@/assets/old/logo_green_5.png"),
      items: require("@/assets/img/items.svg"),
      girl_and_dog: require("@/assets/img/girl_and_dog.svg"),
      arrow_top: require("@/assets/img/arrog_top.svg"),
      ellipses: require("@/assets/img/ellipses.svg"),
      arrow_mission: require("@/assets/img/arrow_mission.svg"),
      help: require("@/assets/img/help.svg"),
      help_icon_1: require("@/assets/img/help_icon_1.svg"),
      help_icon_2: require("@/assets/img/help_icon_2.svg"),
      help_icon_3: require("@/assets/img/help_icon_3.svg"),
      questions: [
        {
          'id': 1,
          'question': 'Это платно?',
          'answer': [
            'Нет. Наш проект полностью благотворительный и направлен на реабилитацию людей с хроническими заболеваниями с помощью собак-помощников.',
            'Участие в программе совершенно бесплатно, однако, количество мест ограничено и ожидание обучения собаки-помощника может продлиться до 1 года. Если все места заняты, то вы можете записаться в следующую волну обучения.'
          ]
        },
        {
          'id': 2,
          'question': 'Сколько длится обучение собаки?',
          'answer': [
            'Обучение может длиться от 6 до 18 месяцев в зависимости от сложности поставленных задач и таких факторов, как базовая кинологическая подготовка собаки, наличие проблем поведения, скорости обучения собаки и вашей готовности быстро пройти подготовительный период.',
            'Перед обучением будет необходимо пройти этапы:',
            '1. Видеоинтервью с руководителем организации или куратором вашего направления (зависит от вашего заболевания) совместно с главным кинологом проекта.',
            '2. Заполнить необходимые анкеты.',
            '3. Пройти тестирование вашей собаки (или щенка).',
            '4. Проконсультироваться с врачом-психиатром нашего проекта.',
            'Только после этих этапов, вы будете допущены к обучению. Вы можете присоединиться к следующей волне, но следует понимать, что ожидание обучения может затянуться до 1,5 лет.'
          ]
        },
        {
          'id': 3,
          'question': 'Что, если у меня есть собака?',
          'answer': [
            'Если у вас уже есть собака, то мы готовы провести специальное тестирование на её рабочие качества: способность к обучению и к дрессировке, тип темперамента и психику.  Мы предъявляем высокие требования к нервной системе, желанию работать и мотивации собак.',
            'К сожалению, если ваша собака не покажет необходимого результата, мы не сможем приступить к обучению вашей собаки.',
            'При желании взять другую собаку специально для работы, мы предоставляем нашу программу помощи выбора породы, заводчика и щенка.'
          ]
        },
        {
          'id': 4,
          'question': 'С какими заболеваниями помогают собаки?',
          'answer': [
            'Собаки-помощники работают со множеством заболеваний от проблем с опорно-двигательным аппаратом до ментальных расстройств. Наш проект работает с людьми с эпилепсией, аллергией, диабетом, сердечно-сосудистыми заболеваниями, синдромом дефицита внимания и гиперактивности (СДВГ), расстройством аутистического спектра, тревожными расстройствами, аффективными расстройствами, шизофренией и другими ментальными расстройствами.'
          ]
        },
        {
          'id': 5,
          'question': 'Подходят ли для работы собаки из приюта?',
          'answer': [
            'В нашем проекте высокие требования к собакам-помощникам, как к служебным собакам: к рабочему потенциалу, к психическому и физическому здоровью, к нервной системе. От работы собаки-помощника зависит жизнь и благополучие человека.',
            'К сожалению, в приютах сложно найти подходящую собаку для работы из-за травмирующих условий проживания и отсутствия должной социализации. Мы не можем гарантировать, что собака из приюта сможет подойти для работы и пройти тестирование.',
            'При вашем желании, мы можем протестировать вашу собаку. Если собака не пройдёт тестирование, мы не сможем приступить к обучению и дальнейшей работе.',
            'В таком случае мы можем вам помочь выбрать другую собаку (например, приобрести с помощью нашей "Выбираем щенка вместе"), чтобы быть уверены в рабочих качествах собаки.'
          ]
        },
        {
          'id': 6,
          'question': 'Кто будет обучать собаку?',
          'answer': [
            'Обучать собаку будет кинолог из нашего проекта, который будет закреплен за вами. Занятия проходят индивидуально в подходящем вам режиме.',
            'Мы тщательно отбираем и готовим наших кинологов: они проходят международное обучение, постоянно консультируются с профильными врачами, сдают экзамены на знание заболеваний, с которыми мы работаем, и находятся под контролем главного кинолога-методиста.',
            'Руководитель направления и руководитель проекта стараются подобрать кинолога, с которым вам будет легко и комфортно работать. Ваш эмоциональный комфорт важен для прогресса обучения и психического здоровья.'
          ]
        },
        {
          'id': 7,
          'question': 'Что, если я живу далеко?',
          'answer': [
            'У нас широкая география! При возможности мы стремимся подобрать кинолога ближе к вашему месту проживания. Если у вас нет возможности заниматься с кинологом оффлайн, то мы подбираем вам обучение с кинологом онлайн.',
            'Мы всегда рады новым кинологам в нашем проекте! Если у вас или ваших знакомых есть кинологическое образование, то мы будем рады, если вы присоединитесь к нашему проекту или порекомендуете его для сотрудничества.'
          ]
        }
      ],
      article_1: require("@/assets/img/article_1.svg"),
      article_2: require("@/assets/img/article_2.svg"),
      article_3: require("@/assets/img/article_3.svg"),
    };
  },
}
</script>


<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
@import "@glidejs/glide/src/assets/sass/glide.core";

.hr-block {
  height: 2px;
  background-color: $dark-green;
  opacity: 40%;
  @media screen and (max-width: $width-l) {
    margin-top: 40px;
  }
}

.body {
  background-color: $olive-light;
  z-index: 1;
  position: relative;
}
.main-block {
  display: flex;
  align-items: center;
  &.between {
    justify-content: space-between;
  }
  &.end {
    justify-content: flex-end;
  }
  &.around {
    justify-content: space-around;
  }
  &.start {
    justify-content: flex-start;
  }
}

.items {
  width: 106px;
  position: absolute;
  left: -123px;
  top: -11px;
  @media screen and (max-width: $width-xl) {
    width: 88px;
    position: absolute;
    left: -106px;
    top: -15px;
  }
  @media screen and (max-width: $width-l) {
    left: -85px;
  }
  @media screen and (max-width: $width-m) {
    left: -65px;
    width: 61px;
    top: -15px;
  }
}

.girl_and_dog {
  margin-top: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: $width-l) {
    flex-direction: column;
  }
  .arrow_top {
    position: absolute;
    top: 24px;
    right: -2px;
    @media screen and (max-width: $width-xl) {
      display: none;
    }
  }
  .relative {
    width: 50%;
    @media screen and (max-width: $width-l) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .img {
    width: 50%;
    max-width: 486px;
    height: auto;
    @media screen and (max-width: $width-xl) {
      max-width: 417px;
    }
    @media screen and (max-width: $width-l) {
      max-width: 460px;
    }
    @media screen and (max-width: $width-m) {
      width: 100%;
      max-width: 338px;
    }
  }
  .button {
    margin-top: 42px;
    @media screen and (max-width: $width-l) {
      margin-top: 40px;
    }
    @media screen and (max-width: $width-m) {
      width: auto;
    }
  }
  .p {
    width: 100%;
    max-width: 540px;
    @media screen and (max-width: $width-xl) {
      max-width: 447px;
    }
    @media screen and (max-width: $width-l) {
      text-align: center;
      margin-top: 40px;
    }
  }
}

.home-block {
  margin: auto;
  padding-top: 100px;
  max-width: 1160px;
  @media screen and (max-width: $width-xl) {
    max-width: 936px;
  }
  @media screen and (max-width: $width-m) {
    padding-top: 79px;
  }
  .header-first {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: $width-xl) {
      justify-content: space-evenly;
    }
    @media screen and (max-width: $width-l) {
      margin-right: 123px;
    }
    @media screen and (max-width: $width-m) {
      margin-right: auto;
    }
    button {
      @media screen and (max-width: $width-l) {
        display: none;
      }
    }

  }
  .header-second {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media screen and (max-width: $width-xl) {
      justify-content: center;
    }
    @media screen and (max-width: $width-l) {
      margin-left: 30px;
    }
    @media screen and (max-width: $width-m) {
      margin-left: auto;
    }
    h1 {
      @media screen and (max-width: $width-m) {
        max-width: 318px;
      }
    }
  }
}

.ellipses-wrapper {
  position: absolute;
  display: block;
  width: 100%;
  top: -117px;
  height: 2000px;
  z-index: -1;
  background-position: top;
  background-size: auto;
  background-repeat: no-repeat;
  @media screen and (max-width: $width-l) {
    top: 80px;
    height: 1700px;
    background-size: cover;
  }
  @media screen and (max-width: $width-m) {
    top: -78px;
    height: 2060px;
    background-size: cover;
  }
}

.ellipses {
  width: 1670px;
  margin-top: -108px;
  z-index: -1;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.mission {
  margin-top: 107px;
  @media screen and (max-width: $width-m) {
    margin-top: 60px;
  }
  .arrow_mission {
    position: absolute;
    top: 30px;
    left: 170px;
    @media screen and (max-width: $width-xl) {
      display: none;
    }
  }

  h3 {
    margin-top: 45px;
  }
  .wrapper {
    width: 100%;
    align-items: start;
    @media screen and (max-width: $width-l) {
      display: flex;
      flex-direction: column;
    }
  }
  .block {
    width: 50%;
    @media screen and (max-width: $width-l) {
      width: 100%;
    }
    .text {
      max-width: 369px;
    }
  }
  .first-block {
    .text {
      @media screen and (max-width: $width-l) {
        max-width: 516px;
      }
    }
  }
  .second-block {
    @media screen and (max-width: $width-l) {
      margin-top: 80px;
    }
  }

  .text {
    margin-top: 30px;
  }

  .button {
    margin-top: 60px;
  }
}

.info {
  height: 294px;
  border-radius: 60px;
  background-color: $olive-light;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 102px;
  padding-right: 102px;

  @media screen and (max-width: $width-l) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (max-width: $width-l) {
    height: 210px;
  }
  @media screen and (max-width: $width-m) {
    height: auto;
    flex-direction: column;
  }

  .item {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    align-items: center;
    h2 {
      @media screen and (max-width: $width-m) {
        font-size: 56px;
      }
    }
    @media screen and (max-width: $width-m) {
      margin-bottom: 30px;
      margin-top: 30px;
    }
    hr {
      width: 198px;
      height: 2px;
      background-color: $dark-green;
      margin-top: 20px;
      margin-bottom: 20px;
      @media screen and (max-width: $width-l) {
        width: 120px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      @media screen and (max-width: $width-m) {
        width: 290px;
        background-color: $dark-green-40;
      }
    }
  }
  .text {
    color: $dark-green;
    @media screen and (max-width: $width-m) {
      font-size: 24px;
    }
  }
}

.help-block {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  @media screen and (max-width: $width-l) {
    justify-content: space-between;
    margin-top: 80px;
  }
  .help {
    width: 100%;
    max-width: 473px;
  }
  .image-item {
    @media screen and (max-width: $width-xl) {
      display: none;
    }
  }
  .text-item {
    @media screen and (max-width: $width-xl) {
      margin-left: -92px;
    }
    @media screen and (max-width: $width-l) {
      margin-left: 0;
    }
  }
  .item {
    width: 50%;
    @media screen and (max-width: $width-xl) {
      width: 100%;
    }
    .help-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 40px;
      @media screen and (max-width: $width-xl) {
        margin-top: 30px;
        max-width: 562px;
      }
      p {
        @media screen and (max-width: $width-m) {
          max-width: 373px;
        }
      }
      span {
        text-decoration: underline;
        text-underline-offset: 5px;
        text-decoration-thickness: 1px;
      }
      img {
        margin-right: 30px;
        width: 72px;
      }
    }
    button {
      margin-top: 60px;
    }
  }
}

.arrow-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .placeholder {
    width: 100px;
  }
  .arrow_long_right {
    flex: 1;
    height: 2px;
    background-color: $dark-green;
    margin-right: 60px;
    @media screen and (max-width: $width-l) {
      margin-right: 100px;
    }
    @media screen and (max-width: $width-m) {
      display: none;
    }
  }
}

.article-block {
  .wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $width-xl) {
      flex-direction: column;
    }
  }
  .item {
    @media screen and (max-width: $width-xl) {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 40px;
    }
    @media screen and (max-width: $width-m) {
     flex-direction: column;
    }
    img {
        @media screen and (max-width: $width-l) {
          max-width: 272px;
        }
    }

    .p {
      max-width: 340px;
      margin-top: 20px;
      margin-bottom: 30px;
      @media screen and (max-width: $width-l) {
        margin-bottom: 20px;
      }
    }
    .inner {
      @media screen and (max-width: $width-xl) {
        margin-left: 46px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      @media screen and (max-width: $width-m) {
        margin-left: 0;
        max-width: 250px;
      }
      button {
        @media screen and (max-width: $width-m) {
          padding: 27px 41px 27px 41px;
        }
      }
    }
  }
}

</style>
<template>
  <div class="m_100">
    <bread-crumbs>О нас</bread-crumbs>
  </div>
  <div class="main-wrapper">
    <div>
      <div class="w_50_wrapper m_100">
        <div class="w_50">
          <h1>О НАС</h1>
        </div>
        <div class="w_50">
          <div class="line"></div>
          <div><h2>Мы напрявляем – собаки помогают</h2></div>
        </div>
      </div>
      <div class="p-about m_50 head_text">
        Мы создали благотворительный проект «Дело в собаке», посвящённый собакам-помощникам для людей с&nbsp;ментальными, неврологическими, эндокринными и&nbsp;другими заболеваниями
      </div>
      <div class="w_50_wrapper m_100 help_text">
        <div class="text w_50">Во многих странах существуют специально обученные собаки для людей с хроническими заболеваниями, благодаря которым люди имеют возможность получать симптоматическую помощь и постоянную реабилитацию.</div>
        <div class="w_50">
          <button-simple mode="dark">Хочу помочь проекту</button-simple>
        </div>
      </div>
      <div class="line help_line m_50"></div>
      <div class="arrow-block m_100">
        <div class="arrow_long_right"></div>
          <button-simple mode="dark">Хочу помочь проекту</button-simple>
      </div>
      <div class="p-about m_50 dogs_text">
        Собаки-помощники помогают в устранении и&nbsp;уменьшении симптомов заболевания, снижают тревогу, оказывают помощь при приступах и способствуют успешной реабилитации и&nbsp;долгосрочной ремиссии:
      </div>
      <div class="info w_50_wrapper m_100 p">
        <div class="item w_50">
          <div class="circle"></div>
          <div class="item_text">
            Помогают остановить или&nbsp;купировать уже&nbsp;начавшийся приступ
          </div>
        </div>
        <div class="item w_50">
          <div class="circle"></div>
          <div class="item_text">
            Предупреждают, что в пище или лекарствах содержатся вещества-аллергены
          </div>
        </div>
        <div class="item w_50">
          <div class="circle"></div>
          <div class="item_text">
            Поднимают или приносят нужную вещь
          </div>
        </div>
        <div class="item w_50">
          <div class="circle"></div>
          <div class="item_text">
            Помогают успокоиться и даже остановить паническую атаку
          </div>
        </div>
        <div class="item w_50">
          <div class="circle"></div>
          <div class="item_text">
            Напоминают, что пришло время принять лекарство или сделать медицинскую процедуру
          </div>
        </div>
        <div class="item w_50">
          <div class="circle"></div>
          <div class="item_text">
            Оповещают о падении сахара в крови
          </div>
        </div>
        <div class="item w_50">
          <div class="circle"></div>
          <div class="item_text">
            Ограждают от контакта с большим скоплением людей, если это необходимо 
          </div>
        </div>
      </div>
      <div class="line help_line m_50"></div>
      <div class="p-about footer_text m_100">
        <div class="m_50">
          В России направление по работе с собаками-помощниками только&nbsp;начинает развиваться.

        </div>
        <div>
Мы прилагаем все усилия для того, чтобы собаки-помощники стали доступными, облегчив жизнь огромной части населения.

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import ButtonSimple from "@/components/ButtonSimple.vue";

export default {
  name: "AboutPage",
  components: {ButtonSimple, BreadCrumbs},
  computed: {
    ...mapState({
      members: (state) => state.member.members,
      pages: (state) => state.page.pages,
    }),
  },
  mounted() {
    document.title = 'Дело в собаке | О нас';
  },
  data() {
    return {};
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

h1 {
  color: $black;
}

.help_text {
  align-items: center;
  button {
    @media screen and (max-width: $width-l) {
      display: none;
    }
  }

  .text {
      max-width: 480px;
  }
}
.w_50_wrapper {
  display: flex;
  justify-content: space-between;
  .w_50 {
    width: 50%;
    @media screen and (max-width: $width-l) {
      width: 100%;
    }
  }
}

.about_text {
  max-width: 870px;
}


.line {
  width: 297px;
  height: 1px;
  background-color: $dark-green;

}
.help_line {
  @media screen and (max-width: $width-l) {
    display: none;
  }
}

.dogs_text {
  max-width: 1000px;
}
.head_text {
  max-width: 859px;
}
.footer_text {
  max-width: 1000px;
  @media screen and (max-width: $width-l) {
    display: none;
  }
}

.info {
  &.w_50_wrapper {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .circle {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: $dark-green;
  }
  .item {
    display: flex;
    margin-bottom: 25px;
    .item_text {
      max-width: 400px;
      margin-left: 20px;
      @media screen and (max-width: $width-l) {
        max-width: 100%;
      }
    }
  }
}


.arrow-block {
  display: none;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: $width-l) {
      display: flex;
  }
  .placeholder {
    width: 100px;
  }
  .arrow_long_right {
    flex: 1;
    height: 2px;
    background-color: $dark-green;
    margin-right: 60px;
    @media screen and (max-width: $width-l) {
      margin-right: 100px;
    }
    @media screen and (max-width: $width-m) {
      display: none;
    }
  }
}

</style>
import Client from "@/repositories/Client";

const resource = "/feedback";

export default {
  create(name, email, phone, message) {
    let data = {};
    if (name) {
        data['name'] = name;
    }
    if (email) {
        data['email'] = email;
    }
    if (phone) {
        data['phone'] = phone;
    }
    if (message) {
        data['message'] = message;
    }

    return Client.post(
        `${resource}/`,
        data,
    );
  }
};

import {createRouter, createWebHistory} from "vue-router";
import HomePage from "@/pages/HomePage";
import AboutPage from "@/pages/AboutPage";
import InfoPage from "@/pages/InfoPage.vue";
import HelpersPage from "@/pages/HelpersPage";
import PartnersPage from "@/pages/PartnersPage";
import TeamPage from "@/pages/TeamPage";
// import CoursesPage from "@/pages/CoursesPage";
// import PostersPage from "@/pages/PostersPage.vue";

const routes = [
  {
    path: "/",
    component: HomePage,
    name: 'Главная',
  },
  {
    path: "/about",
    component: AboutPage,
    name: 'О нас',
  },
  {
    path: "/info",
    component: InfoPage,
    name: 'Информация',
  },
  {
    path: "/helpers",
    component: HelpersPage,
    name: 'Наша деятельность',
  },
  {
    path: "/partners",
    component: PartnersPage,
    name: 'Партнеры',
  },
  {
    path: "/team",
    component: TeamPage,
    name: 'Наша команда',
  },
  // {
  //   path: "/courses",
  //   component: CoursesPage,
  //   name: 'Наши курсы',
  // },
  // {
  //   path: "/posters",
  //   component: PostersPage,
  //   name: 'Статьи',
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: "active",
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0)
})

export default router;

<template>
  <button
      class="button"
      :class="{ 'dark': this.mode === 'dark' }"
  >
    <slot></slot> →
  </button>
</template>

<script>
export default {
  name: "ButtonSimple",
  props: [
      "click",
      "mode"
  ]
}
</script>

<style scoped lang="scss">
@import "@/assets/css/variables.scss";

.button {
  font-size: 18px;
  line-height: 140%;
  font-family: $font-family-main;
  color: $dark-green;
  height: 80px;
  border-radius: 60px;
  padding: 25px 40px 25px 40px;
  border: 2px solid $dark-green;
  text-transform: uppercase;
  &.dark {
    color: $white;
    background-color: $dark-green;
  }
  @media screen and (max-width: $width-m) {
    padding: 27px 69px 27px 69px;
    width: 100%;
  }
  @media screen and (max-width: $width-s) {
      font-size: 16px;
  }
}
</style>
import RepositoryFactory from "@/repositories/RepositoryFactory";

const ShortcutRepository = RepositoryFactory.get("shortcut");

export const shortcutModule = {
  state: () => ({
    shortcuts: [],
  }),
  getters: {},
  mutations: {
    setShortcuts(state, shortcuts) {
      state.shortcuts = shortcuts;
    },
    updateShortcut(state, payload) {
      state.shortcuts = state.shortcuts.map((shortcut) => {
        if (shortcut.id === payload.id) {
          return Object.assign({}, shortcut, payload);
        }
        return shortcut;
      });
    },
  },
  actions: {
    fetchShortcuts(state) {
      return new Promise((resolve, reject) => {
          ShortcutRepository.list()
            .then((response) => {
              state.commit("setShortcuts", response.data);
              resolve();
            })
            .catch((e) => {
              reject(e);
            });
        })
    },
  },
  namespaced: true,
};

import RepositoryFactory from "@/repositories/RepositoryFactory";

const MemberRepository = RepositoryFactory.get("member");

export const memberModule = {
  state: () => ({
    members: [],
  }),
  getters: {},
  mutations: {
    setMembers(state, members) {
      state.members = members;
    },
    updateMember(state, payload) {
      state.members = state.members.map((member) => {
        if (member.id === payload.id) {
          return Object.assign({}, member, payload);
        }
        return member;
      });
    },
  },
  actions: {
    fetchMembers(state) {
      return new Promise((resolve, reject) => {
          MemberRepository.list()
            .then((response) => {
              state.commit("setMembers", response.data);
              resolve();
            })
            .catch((e) => {
              reject(e);
            });
        })
    },
  },
  namespaced: true,
};

<template>
  <header>
    <nav-bar :is_open="is_menu_open"/>
  </header>
  <main>
    <router-view/>
  </main>
  <footer>
    <FooterBar></FooterBar>
  </footer>
</template>

<script>

import NavBar from "@/components/NavBar";
import FooterBar from "@/components/FooterBar.vue";

export default {
  name: "App",
  components: {FooterBar, NavBar},
  data() {
    return {
      is_menu_open: false,
    }
  },
  methods:{
    toggleMenu(){
        this.is_menu_open = !this.is_menu_open;
    }
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Default Title',
      // all titles will be injected into this template
      titleTemplate: '%s | My Awesome Webapp'
  }
};
</script>

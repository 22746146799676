<template>
  <div class="m_100">
    <bread-crumbs>Наша деятельность</bread-crumbs>
  </div>
  <div class="main-wrapper">
    <div class="w_50_wrapper m_50">
      <div class="w_50">
        <h1>НАША ДЕЯТЕЛЬНОСТЬ</h1>
      </div>
    </div>

    <div class="doing-text">
      <h2 class="m_50">НАШ ПРОЕКТ ЗАНИМАЕТСЯ:</h2>
      <div class="what-items p w_50_wrapper wrap">
        <div v-for="item in doing_items" v-bind:key="item.id" class="item w_50">
          <img :src="ok" alt="" class="img">
          <div class="item_text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BreadCrumbs from "@/components/BreadCrumbs.vue";

export default {
  name: "HelpersPage",
  data: function () {
    return {
      ok: require("@/assets/img/ok.svg"),
      doing_items: [
        {
          'id': 1,
          'text': 'Помощью благополучателям и дрессировкой собак-помощников'
        },
        {
          'id': 2,
          'text': 'Проведением исследований в области кинологии, медицины, социологии и медицины, посвященным собакам-помощникам'
        },
        {
          'id': 3,
          'text': 'Переводом зарубежной литературы и адаптацией методик под условия России'
        },
        {
          'id': 4,
          'text': 'Просветительской деятельностью населения для улучшения отношения к собакам в стране и повышением осведомленности о собаках-помощниках'
        },
        {
          'id': 5,
          'text': 'Разработкой стандартов качества и безопасности для обучения собак-помощников'
        },
      ],
    }
  },
  components: { BreadCrumbs },
  computed: {
    ...mapState({
      members: (state) => state.member.members,
      pages: (state) => state.page.pages,
    }),
  },
  mounted() {
    document.title = 'Дело в собаке | Наша деятельность';
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

h1 {
  color: $black;
}

.w_50_wrapper {
  display: flex;
  justify-content: space-between;

  .w_50 {
    width: 100%;

    @media screen and (max-width: $width-l) {
      width: 100%;
    }
  }
}

.wrap {
  flex-direction: row;
  flex-wrap: wrap;
}

.doing-text {
  margin-bottom: 100px;
  &.w_50_wrapper {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;

    img {
      width: 38px;
      margin-top: 3px;
    }

    .item_text {
      margin-left: 20px;
    }
  }
}

.what-items {
  .item:nth-child(odd) {
    flex: 0 1 calc(50% - 30px);
    margin-right: 30px;
  }

  .item {
    padding: 30px;
    width: 50%;
    border-radius: 20px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    img {
      width: 80px;
    }

    .p {
      margin-left: 20px;
    }
  }
}
</style>
